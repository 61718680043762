// Gatsby supports TypeScript natively!
import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import useComponentTranslation from 'hooks/useComponentTranslation'
import Layout from '../components/gatsby/layout'
import SEO from '../components/gatsby/seo'

const Roles = props => {
    const { t } = useComponentTranslation('roles')
    return (
        <Layout>
            <SEO
                title="Roles"
                description="On this page you see all roles and their descriptions"
            />
            <Typography variant="h4" align="center" component="h1">
                Roles
            </Typography>
            <Grid container alignItems="center" justify="center" spacing={3}>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.AMOR')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/amor.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.AMOR')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.WEREWOLF')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/werewolf.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.WEREWOLF')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.LYCANTHROPE')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/lycanthrope.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.LYCANTHROPE')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.WITCH')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/witch.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.WITCH')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.SEER')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/seer.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.SEER')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.PRIEST')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/priest.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.PRIEST')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.VILLAGE_SLUT')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/dream-girl.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.VILLAGE_SLUT')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.VILLAGER')}
                    </Typography>
                    <Grid container justify="center">
                        <img
                            style={{
                                width: 140,
                                display: 'block',
                            }}
                            src="/roles/villager1.png"
                        />
                        <img
                            style={{
                                width: 140,
                                display: 'block',
                            }}
                            src="/roles/villager2.png"
                        />
                    </Grid>
                    <Typography variant="body1">
                        {t('descriptions.VILLAGER')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.HUNTER')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/hunter.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.HUNTER')}
                    </Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    alignItems="center"
                    direction="column"
                >
                    <Typography variant="h5" component="h2">
                        {t('names.TOUGH_GUY')}
                    </Typography>
                    <img
                        style={{
                            width: 140,
                            display: 'block',
                        }}
                        src="/roles/tough-guy.png"
                    />
                    <Typography variant="body1">
                        {t('descriptions.TOUGH_GUY')}
                    </Typography>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default Roles
